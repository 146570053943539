import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Dialog, DialogFooter, DialogHeader, IDialogProps } from '@unione-pro/unione.digital.commons.ui-kit';
// import { SuccessIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/success';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';

const PREFIX = 'DialogUserSuccess';
const useStyles = createUseStyles(
  () => ({
    root: {
      maxWidth: '440px',
    },
  }),
  { name: PREFIX },
);

export const DialogUserCreateSuccess: React.FC<IDialogProps> = (props) => {
  const { onClose, open, ...other } = props;
  const classes = useStyles();

  return (
    <Dialog className={classes.root} onClose={onClose} open={open} {...other} color="success">
      <DialogHeader onClose={onClose} style={{ marginBottom: '31px' }} />
      <Stack alignItems="center" direction="column" spacing={32} style={{ width: '100%' }}>
        <img src="/static/success-create.png" alt="" />

        <Typography fontWeight="bold" variant="h3" align="center">
          Пользователь успешно добавлен!
        </Typography>

        <DialogFooter style={{ width: '100%' }}>
          <Button
            fullWidth
            variant="gradient"
            color="primary"
            style={{ height: '44px', justifyContent: 'center', fontWeight: 500 }}
            onClick={onClose}
          >
            Отлично!
          </Button>
        </DialogFooter>
      </Stack>
    </Dialog>
  );
};
