import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { Header } from '@unione-pro/unione.commons.webpack';
import { AppLayout } from '../views/components/layout';
import { UserListPage } from '../views/content/user-list';
import { PageNotFound } from '../views/lab/page-not-found';
import { BrowserRoute } from './browser.routes';

export const appRoutesDefinition: RouteObject[] = [
  {
    path: '/',
    element: (
      <AppLayout header={<Header />}>
        <Outlet />
      </AppLayout>
    ),

    children: [
      {
        path: '*',
        element: <PageNotFound />,
      },

      { path: BrowserRoute.users, element: <UserListPage /> },
    ],
  },
];
