import React from 'react';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { PageMessage } from '../../lab/page-message';

export const NoServerErrorView: React.FC = () => (
  <PageMessage
    message={
      <div>
        <Typography variant="h2" align="center" fontWeight="bold">
          Сервер временно не доступен
        </Typography>
      </div>
    }
  />
);
