import React, { FC, Fragment, PropsWithChildren } from 'react';
import { goToAuth } from '@unione-pro/unione.commons.webpack';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { LayoutSimple } from '../layout/layout-simple.view';
import { AuthLoader } from './access-loader.view';
import { AuthRedirect } from './auth-redirect';

export const Auth: FC<PropsWithChildren> = observer((props) => {
  const { digital, auth } = useAppStore();

  const user = digital.user.useQuery({ variables: {}, skip: !auth.token });

  React.useEffect(() => {
    if (!auth.token) {
      auth.load().then().catch();
    }
  }, []);

  if (auth.loaded ? user.loading && auth.token : true) {
    return (
      <LayoutSimple>
        <AuthLoader />
      </LayoutSimple>
    );
  }

  if (!auth.token) {
    goToAuth();

    return (
      <LayoutSimple>
        <AuthRedirect />
      </LayoutSimple>
    );
  }

  return <Fragment>{props.children}</Fragment>;
});
