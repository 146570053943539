import { DigitalSdk } from '@unione-pro/unione.digital.commons.sdk';
import {
  IDigitalSdkCreateCourseArgs,
  IDigitalSdkCreateCourseRes,
  IDigitalSdkFindCoursesArgs,
  IDigitalSdkFindCoursesRes,
  IDigitalSdkGetCourseArgs,
  IDigitalSdkGetCourseRes,
  IDigitalSdkUpdateCourseArgs,
  IDigitalSdkUpdateCourseRes,
  IDigitalSdkTotalCoursesArgs,
  IDigitalSdkTotalCoursesRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/course';
import {
  IDigitalSdkGetCourseModerationArgs,
  IDigitalSdkGetCourseModerationRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/course-moderation';
import {
  IDigitalSdkFindDocumentsArgs,
  IDigitalSdkFindDocumentsRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/document';
import {
  IDigitalSdkFindOrganizationArgs,
  IDigitalSdkFindOrganizationRes,
  IDigitalSdkGetOrganizationArgs,
  IDigitalSdkGetOrganizationRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/organization';
import {
  IDigitalSdkFindRequestArgs,
  IDigitalSdkFindRequestRes,
  IDigitalSdkGetRequestArgs,
  IDigitalSdkGetRequestRes,
  IDigitalSdkTotalRequestArgs,
  IDigitalSdkTotalRequestRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/request';
import {
  IDigitalSdkFindOneIdUsersArgs,
  IDigitalSdkFindOneIdUsersRes,
  IDigitalSdkFindUsersArgs,
  IDigitalSdkFindUsersRes,
  IDigitalSdkGetUserArgs,
  IDigitalSdkGetUserRes,
  IDigitalSdkLoginUserArgs,
  IDigitalSdkLoginUserRes,
  IDigitalSdkTotalUsersArgs,
  IDigitalSdkTotalUsersRes,
  IDigitalSdkUserCreateArgs,
  IDigitalSdkUserCreateRes,
  IDigitalSdkUserDeleteArgs,
  IDigitalSdkUserDeleteRes, IDigitalSdkUserUpdateUserArgs, IDigitalSdkUserUpdateUserRes,
} from '@unione-pro/unione.digital.commons.sdk/lib/digital/user';
import { MutationStore, QueryStore } from '@unione-pro/unione.digital.commons.sdk/lib/utils';
import { RootStore } from '../root/root.store';

export class DigitalStore {

  public readonly rootStore: RootStore;
  public readonly sdk: DigitalSdk;

  public readonly requestItem: QueryStore<IDigitalSdkGetRequestRes, IDigitalSdkGetRequestArgs>;
  public readonly requestList: QueryStore<IDigitalSdkFindRequestRes, IDigitalSdkFindRequestArgs>;
  public readonly requestTotal: QueryStore<IDigitalSdkTotalRequestRes, IDigitalSdkTotalRequestArgs>;

  public readonly documentList: QueryStore<IDigitalSdkFindDocumentsRes, IDigitalSdkFindDocumentsArgs>;

  public readonly user: QueryStore<IDigitalSdkLoginUserRes, IDigitalSdkLoginUserArgs>;
  public readonly organization: QueryStore<IDigitalSdkGetOrganizationRes>;

  public readonly courseItem: QueryStore<IDigitalSdkGetCourseRes, IDigitalSdkGetCourseArgs>;
  public readonly courseList: QueryStore<IDigitalSdkFindCoursesRes, IDigitalSdkFindCoursesArgs>;
  public readonly createCourse: MutationStore<IDigitalSdkCreateCourseRes, IDigitalSdkCreateCourseArgs>;
  public readonly updateCourse: MutationStore<IDigitalSdkUpdateCourseRes, IDigitalSdkUpdateCourseArgs>;
  public readonly courseTotal: QueryStore<IDigitalSdkTotalCoursesRes, IDigitalSdkTotalCoursesArgs>;
  public readonly userChangeRole: MutationStore<IDigitalSdkUserUpdateUserRes, IDigitalSdkUserUpdateUserArgs>;

  public readonly courseModerationItem: QueryStore<
  IDigitalSdkGetCourseModerationRes,
  IDigitalSdkGetCourseModerationArgs
  >;

  public readonly userList: QueryStore<IDigitalSdkFindUsersRes, IDigitalSdkFindUsersArgs>;
  public readonly userItem: QueryStore<IDigitalSdkGetUserRes, IDigitalSdkGetUserArgs>;
  public readonly userTotal: QueryStore<IDigitalSdkTotalUsersRes, IDigitalSdkTotalUsersArgs>;
  public readonly userDelete: MutationStore<IDigitalSdkUserDeleteRes, IDigitalSdkUserDeleteArgs>;
  public readonly userCreate: MutationStore<IDigitalSdkUserCreateRes, IDigitalSdkUserCreateArgs>;
  public readonly userOneIdList: QueryStore<IDigitalSdkFindOneIdUsersRes, IDigitalSdkFindOneIdUsersArgs>;

  public readonly organizationItem: QueryStore<IDigitalSdkGetOrganizationRes, IDigitalSdkGetOrganizationArgs>;
  public readonly organizationList: QueryStore<IDigitalSdkFindOrganizationRes, IDigitalSdkFindOrganizationArgs>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.sdk = new DigitalSdk(this.rootStore.config.digitalAPI, this.rootStore.auth.token);

    this.requestItem = new QueryStore((args) => this.sdk.request.get(args));
    this.requestList = new QueryStore((args) => this.sdk.request.find(args));
    this.requestTotal = new QueryStore((args) => this.sdk.request.total(args));

    this.courseItem = new QueryStore((args) => this.sdk.course.get(args));
    this.courseList = new QueryStore((args) => this.sdk.course.find(args));
    this.courseTotal = new QueryStore((args) => this.sdk.course.total(args));
    this.createCourse = new MutationStore((args) => this.sdk.course.create(args));
    this.updateCourse = new MutationStore((args) => this.sdk.course.update(args));

    this.courseModerationItem = new QueryStore((args) => this.sdk.courseModeration.get(args));

    this.documentList = new QueryStore((args) => this.sdk.document.find(args));

    this.user = new QueryStore((args) => this.sdk.user.login(args));
    this.organization = new QueryStore(() => this.sdk.organization.current());

    this.userList = new QueryStore((args) => this.sdk.user.find(args));
    this.userItem = new QueryStore((args) => this.sdk.user.get(args));
    this.userTotal = new QueryStore((args) => this.sdk.user.total(args));
    this.userCreate = new MutationStore((args) => this.sdk.user.create(args));
    this.userDelete = new MutationStore((args) => this.sdk.user.delete(args));
    this.userOneIdList = new QueryStore((args) => this.sdk.user.findOneId(args));
    this.userChangeRole = new MutationStore((args) => this.sdk.user.changeRole(args));

    this.organizationList = new QueryStore((args) => this.sdk.organization.find(args));
    this.organizationItem = new QueryStore((args) => this.sdk.organization.get(args));
  }

  public refresh(): void {
    this.sdk.token = this.rootStore.auth.token;
  }

}
