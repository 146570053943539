import React, { FC, Fragment, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { LayoutSimple } from '../layout/layout-simple.view';
import { NoServerErrorView } from './no-server-error.view';

export const NoServer: FC<PropsWithChildren> = observer((props) => {
  const { digital } = useAppStore();

  const { data } = digital.user.useQuery({ variables: {}, skip: true });

  if (!data) {
    return (
      <LayoutSimple>
        <NoServerErrorView />;
      </LayoutSimple>
    );
  }

  return <Fragment>{props.children}</Fragment>;
});
