import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import { Layout } from '@unione-pro/unione.digital.commons.ui-kit/lib/layout';
import { LayoutContent } from '@unione-pro/unione.digital.commons.ui-kit/lib/layout/layout-content.view';
import { Tab } from '@unione-pro/unione.digital.commons.ui-kit/lib/tab/tab.view';
import { Tabs } from '@unione-pro/unione.digital.commons.ui-kit/lib/tabs';
import { BrowserRoute } from '../../../routes/browser.routes';
import { AppFooter } from '../app-footer';

export const AppLayout: FC<{ children: React.ReactNode; sidebar?: React.ReactNode; header: React.ReactNode }> = (
  props,
) => {
  const location = useLocation();

  const value = React.useMemo((): string => {
    if (location.pathname === BrowserRoute.users) {
      return BrowserRoute.users;
    }

    return '';
  }, [location.pathname]);

  return (
    <>
      {props.header}

      <Layout>
        <Tabs value={value} style={{ width: '100%', padding: '0px 24px' }}>
          <Tab
            value={BrowserRoute.users}
            label="Управление пользователями"
            component={(p): JSX.Element => <Link to={BrowserRoute.users} {...p} />}
          />
        </Tabs>
        <LayoutContent>{props.children}</LayoutContent>
      </Layout>

      <AppFooter />
    </>
  );
};
