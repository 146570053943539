"use strict";
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = function(target, all) {
    for(var name in all)__defProp(target, name, {
        get: all[name],
        enumerable: true
    });
};
var __copyProps = function(to, from, except, desc) {
    if (from && typeof from === "object" || typeof from === "function") {
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            var _loop = function() {
                var key = _step.value;
                if (!__hasOwnProp.call(to, key) && key !== except) __defProp(to, key, {
                    get: function() {
                        return from[key];
                    },
                    enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable
                });
            };
            for(var _iterator = __getOwnPropNames(from)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true)_loop();
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    }
    return to;
};
var __toCommonJS = function(mod) {
    return __copyProps(__defProp({}, "__esModule", {
        value: true
    }), mod);
};
// src/index.ts
var src_exports = {};
__export(src_exports, {
    formatAuthor: function() {
        return formatAuthor;
    },
    formatSharedUser: function() {
        return formatSharedUser;
    },
    getUserBirthDate: function() {
        return getUserBirthDate;
    },
    getUserFio: function() {
        return getUserFio;
    },
    getUserFioFull: function() {
        return getUserFioFull;
    },
    getUserGeo: function() {
        return getUserGeo;
    },
    isOperator: function() {
        return isOperator;
    },
    isOperatorAdmin: function() {
        return isOperatorAdmin;
    },
    isProvider: function() {
        return isProvider;
    },
    isProviderAdmin: function() {
        return isProviderAdmin;
    },
    userGenderToLocale: function() {
        return userGenderToLocale;
    },
    userModerationDocumentsToLocale: function() {
        return userModerationDocumentsToLocale;
    },
    userRoleToLocale: function() {
        return userRoleToLocale;
    }
});
module.exports = __toCommonJS(src_exports);
// src/utils/access.ts
var import_unione_digital_commons = require("@unione-pro/unione.digital.commons.sdk");
var isOperatorAdmin = function(user) {
    return [
        import_unione_digital_commons.DigitalUserRole.admin,
        import_unione_digital_commons.DigitalUserRole.operatorAdmin
    ].includes(user.role);
};
var isOperator = function(user) {
    return [
        import_unione_digital_commons.DigitalUserRole.admin,
        import_unione_digital_commons.DigitalUserRole.operator,
        import_unione_digital_commons.DigitalUserRole.operatorAdmin
    ].includes(user.role);
};
var isProviderAdmin = function(user) {
    return [
        import_unione_digital_commons.DigitalUserRole.admin,
        import_unione_digital_commons.DigitalUserRole.providerAdmin
    ].includes(user.role);
};
var isProvider = function(user) {
    return [
        import_unione_digital_commons.DigitalUserRole.admin,
        import_unione_digital_commons.DigitalUserRole.provider,
        import_unione_digital_commons.DigitalUserRole.providerAdmin
    ].includes(user.role);
};
// src/utils/format.ts
var import_unione_digital_commons2 = require("@unione-pro/unione.digital.commons.sdk");
var userModerationDocumentsToLocale = function(value) {
    var _obj;
    return (_obj = {}, _defineProperty(_obj, import_unione_digital_commons2.DigitalUserModerationStatus.accepted, "Подтверждены"), _defineProperty(_obj, import_unione_digital_commons2.DigitalUserModerationStatus.declined, "Отклонены"), _defineProperty(_obj, import_unione_digital_commons2.DigitalUserModerationStatus.onModeration, "На рассмотрении"), _obj)[value] || "unknown";
};
// src/utils/user-gender.ts
var import_unione_digital_commons3 = require("@unione-pro/unione.digital.commons.sdk");
var userGenderToLocale = function(gender) {
    switch(gender){
        case import_unione_digital_commons3.Gender.female:
            {
                return "Женский";
            }
        case import_unione_digital_commons3.Gender.male:
            {
                return "Мужской";
            }
        default:
            return "-";
    }
};
// src/utils/user-get-birthdate.ts
var getUserBirthDate = function(user) {
    var birthday = user.user.user_info.birthday;
    if (birthday) {
        var _user_user_user_info_birthday = user.user.user_info.birthday, day = _user_user_user_info_birthday.day, month = _user_user_user_info_birthday.month, year = _user_user_user_info_birthday.year;
        var d = new Date();
        d.setDate(day);
        d.setMonth(month - 1);
        d.setFullYear(year);
        return d;
    }
    return void 0;
};
// src/utils/user-get-fio.ts
var shortStr = function(str) {
    if (str) {
        return str.slice(0, 1) + ".";
    }
};
var getUserFio = function(user) {
    var _user_user_user_info_passport = user.user.user_info.passport, name = _user_user_user_info_passport.name, sur_name = _user_user_user_info_passport.sur_name, patronymic = _user_user_user_info_passport.patronymic;
    var endStr = [
        shortStr(name),
        shortStr(patronymic)
    ].filter(Boolean).join("");
    return [
        sur_name,
        endStr
    ].filter(Boolean).join(" ");
};
var getUserFioFull = function(user) {
    var _user_user_user_info_passport = user.user.user_info.passport, name = _user_user_user_info_passport.name, sur_name = _user_user_user_info_passport.sur_name, patronymic = _user_user_user_info_passport.patronymic;
    return [
        sur_name,
        name,
        patronymic
    ].filter(Boolean).join(" ");
};
// src/utils/user-get-geo.ts
var getUserGeo = function(user) {
    return user && user.user && user.user.user_info ? [
        user.user.user_info.geo.city,
        user.user.user_info.geo.fias_addr
    ].filter(Boolean).join(", ") : "-";
};
// src/utils/user-role-to-locale.ts
var import_unione_digital_commons4 = require("@unione-pro/unione.digital.commons.sdk");
var userRoleToLocale = function(role) {
    var _obj;
    return (_obj = {}, _defineProperty(_obj, import_unione_digital_commons4.DigitalUserRole.admin, "Админ"), _defineProperty(_obj, import_unione_digital_commons4.DigitalUserRole.provider, "Провайдер"), _defineProperty(_obj, import_unione_digital_commons4.DigitalUserRole.operator, "Оператор"), _defineProperty(_obj, import_unione_digital_commons4.DigitalUserRole.operatorAdmin, "Оператор-Админ"), _defineProperty(_obj, import_unione_digital_commons4.DigitalUserRole.providerAdmin, "Провайдер-Админ"), _defineProperty(_obj, import_unione_digital_commons4.DigitalUserRole.user, "Пользователь"), _defineProperty(_obj, import_unione_digital_commons4.DigitalUserRole.bank, "Bank"), _obj)[role] || "unknown";
};
// src/utils/format-author.ts
var formatAuthor = function(author) {
    return "".concat(author.first_name, " ").concat(author.sur_name, " ").concat(author.patronymic);
};
// src/utils/format-shared-user.ts
var formatSharedUser = function(author) {
    return "".concat(author.first_name, " ").concat(author.sur_name, " ").concat(author.patronymic);
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
    formatAuthor: formatAuthor,
    formatSharedUser: formatSharedUser,
    getUserBirthDate: getUserBirthDate,
    getUserFio: getUserFio,
    getUserFioFull: getUserFioFull,
    getUserGeo: getUserGeo,
    isOperator: isOperator,
    isOperatorAdmin: isOperatorAdmin,
    isProvider: isProvider,
    isProviderAdmin: isProviderAdmin,
    userGenderToLocale: userGenderToLocale,
    userModerationDocumentsToLocale: userModerationDocumentsToLocale,
    userRoleToLocale: userRoleToLocale
});
