import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSearch } from '@app/ui-utils';
import { DigitalUserRole, IOneIdUser } from '@unione-pro/unione.digital.commons.sdk';
import { Button, Dialog, DialogHeader } from '@unione-pro/unione.digital.commons.ui-kit';
import { Autocomplete } from '@unione-pro/unione.digital.commons.ui-kit/lib/autocomplete';
import { FormLabel } from '@unione-pro/unione.digital.commons.ui-kit/lib/form-label';
import { UserIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/user';
import { Select, SelectItem } from '@unione-pro/unione.digital.commons.ui-kit/lib/select';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { useDebounce } from 'ahooks';
import cn from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { DialogUserCreateProps } from './dialog-user-create.models';

const PREFIX = 'DialogUserCreate';
const useStyles = createUseStyles(() => ({ root: { gap: '40px', maxWidth: '500px' } }), { name: PREFIX });

export const DialogUserCreate: React.FC<DialogUserCreateProps> = observer((exampleProps) => {
  const { className, onSubmit, onClose, open, ...other } = exampleProps;

  const { digital } = useAppStore();
  const classes = useStyles();

  const rootCN = cn(classes.root, className);

  const search = useSearch();
  const [user, setUser] = React.useState<IOneIdUser | null>(null);
  const [role, setRole] = React.useState<DigitalUserRole>(DigitalUserRole.operator);

  const {
    data: rows,
    loading,
    error,
  } = digital.userOneIdList.useQuery({
    variables: { search: search.debounced, page: 1, limit: 5 },
    skip: !search,
  });

  const handleCreateUser = (): void => {
    if (!user) {
      return;
    }

    if (onSubmit) {
      onSubmit(user, role);
    }
  };

  return (
    <Dialog color="primary" open={open} className={rootCN} onClose={onClose} {...other}>
      <DialogHeader onClose={onClose}>
        <UserIcon color="primary" style={{ width: '28px', height: '28px' }} />
      </DialogHeader>

      <Stack direction="column" spacing={30} style={{ marginTop: 40 }}>
        <Stack direction="column" spacing={12}>
          <Typography variant="h3" fontWeight="bold">
            Добавить пользователя
          </Typography>
          <FormLabel style={{ paddingRight: '108px' }}>
            Чтобы добавить пользователя, начните вводить его e-mail
          </FormLabel>
        </Stack>

        <Stack direction="column" spacing={24}>
          <Stack direction="column" spacing={4} style={{ flex: 2 }}>
            <FormLabel required>E-mail</FormLabel>
            <Autocomplete<IOneIdUser>
              placeholder="Введите e-mail"
              options={rows || []}
              inputValue={search.value}
              onInputChange={(v): void => search.change(v)}
              onChange={(v): void => setUser(v)}
              fullWidth
              value={user}
              getOptionLabel={(v): string => v.email || ''}
            />
          </Stack>

          <Stack direction="column" spacing={4}>
            <FormLabel required>Роль</FormLabel>
            <Select value={role} onChange={(v): void => setRole(v as DigitalUserRole)} fullWidth>
              <SelectItem value={DigitalUserRole.user}>Пользователь</SelectItem>
              <SelectItem value={DigitalUserRole.operator}>Оператор</SelectItem>
              <SelectItem value={DigitalUserRole.provider}>Провайдер</SelectItem>
              <SelectItem value={DigitalUserRole.providerAdmin}>Провайдер-Админ</SelectItem>
              <SelectItem value={DigitalUserRole.operatorAdmin}>Оператор-Админ</SelectItem>
            </Select>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={4} alignItems="center" style={{ flex: 1 }}>
          <Button
            fullWidth
            variant="contained"
            style={{ justifyContent: 'center', height: '44px' }}
            onClick={handleCreateUser}
            disabled={!user}
          >
            Добавить пользователя
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="gray"
            style={{ justifyContent: 'center', height: '44px', color: '#6F7C98', border: 'none' }}
            onClick={onClose}
          >
            Отмена
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
});
