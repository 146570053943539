import React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { toHumanDate, useSearch } from '@app/ui-utils';
import { getUserFio, userRoleToLocale } from '@app/user';
import {
  DigitalUserOrderBy,
  DigitalUserRole,
  IDigitalUser,
  IOneIdUser,
  Order,
} from '@unione-pro/unione.digital.commons.sdk';
import { getErrorMessage } from '@unione-pro/unione.digital.commons.sdk/lib/utils';
import { Button } from '@unione-pro/unione.digital.commons.ui-kit';
import { Confirm } from '@unione-pro/unione.digital.commons.ui-kit/lib/confirm';
import { useDialogState } from '@unione-pro/unione.digital.commons.ui-kit/lib/dialog/dialog.hook';
import { AddIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/add';
import { DeleteIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/delete';
import { SearchIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/search';
import { Menu } from '@unione-pro/unione.digital.commons.ui-kit/lib/menu';
import { MenuItem } from '@unione-pro/unione.digital.commons.ui-kit/lib/menu/menu-item.view';
import { useMenuState } from '@unione-pro/unione.digital.commons.ui-kit/lib/menu/menu.hook';
import { Paper } from '@unione-pro/unione.digital.commons.ui-kit/lib/paper';
import { onErrorSnackbar, snackbar } from '@unione-pro/unione.digital.commons.ui-kit/lib/snackbar/snackbar.utils';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Table } from '@unione-pro/unione.digital.commons.ui-kit/lib/table';
import {
  TablePagination,
  useTablePaginationState,
} from '@unione-pro/unione.digital.commons.ui-kit/lib/table-pagination';
import { TableSortLabel } from '@unione-pro/unione.digital.commons.ui-kit/lib/table-sort-label';
import { tableCellId, tableCellMore } from '@unione-pro/unione.digital.commons.ui-kit/lib/table/table.columns';
import { TextField } from '@unione-pro/unione.digital.commons.ui-kit/lib/text-field';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import { observer } from 'mobx-react';
import { ColumnsType } from 'rc-table/lib/interface';
import { useAppStore } from '../../../stores/root/root.context';
import { DialogUserCreate } from '../../components/dialog-user-create';
import { DialogUserCreateSuccess } from '../../components/dialog-user-create-success';
import { DialogUserEdit } from '../../components/dialog-user-edit';

const PREFIX = 'UserList';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
    },
    menuItemDelete: {
      color: theme.palette.error.main,
    },
  }),
  { name: PREFIX },
);

export const UserListPage: React.FC = observer(() => {
  const { digital } = useAppStore();
  const navigate = useNavigate();
  const classes = useStyles();

  const menuState = useMenuState();
  const [selectedUser, setSelectedUser] = React.useState<IDigitalUser | null>(null);

  const search = useSearch();
  const [order, setOrder] = React.useState<Order>(Order.desc);
  const [orderBy, setOrderBy] = React.useState<DigitalUserOrderBy>(DigitalUserOrderBy.createdAt);
  const pagination = useTablePaginationState(0, 10);

  const {
    data,
    loading,
    error,
    refetch: refetchUsers,
  } = digital.userList.useQuery({
    variables: {
      offset: pagination.rowsPerPage * pagination.page,
      limit: pagination.rowsPerPage,
      search: search.debounced,
      order,
      orderBy,
    },
  });
  const { data: total = 0, refetch: refetchTotal } = digital.userTotal.useQuery({
    variables: {},
  });
  const [createUser] = digital.userCreate.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteUser] = digital.userDelete.useMutation({
    onError: onErrorSnackbar,
  });
  const [changeRole] = digital.userChangeRole.useMutation({
    onError: onErrorSnackbar,
  });

  const confirmDelete = useDialogState<IDigitalUser | null>();
  const editDialog = useDialogState<IDigitalUser | null>();
  const dialogCreate = useDialogState();
  const dialogSuccess = useDialogState();

  const handleDelete = (user: IDigitalUser): void => {
    deleteUser({ id: user._id })
      .then((): void => {
        snackbar({ message: 'Пользователь успешно удален', severity: 'success' });

        refetchUsers().then();
        refetchTotal().then();
      });
  };
  const handleCreate = (oneIdUser: IOneIdUser, role: DigitalUserRole): void => {
    dialogCreate.close();

    createUser({ user_id: oneIdUser.id, role }).then((): void => {
      dialogSuccess.open();

      refetchUsers().then();
      refetchTotal().then();
    });
  };
  const handleEdit = (user: IDigitalUser, role: DigitalUserRole): void => {
    editDialog.close();

    changeRole({ id: user._id, role })
      .then((): void => {
        snackbar({ message: 'Роль успешно обновлена', severity: 'success' });

        refetchUsers().then();
      });
  };

  const columns: ColumnsType<IDigitalUser> = [
    tableCellId({}),
    {
      title: (
        <TableSortLabel
          order={orderBy === DigitalUserOrderBy.surname ? order : undefined}
          onChangeOrder={(v): void => {
            setOrder(v as Order);
            setOrderBy(DigitalUserOrderBy.surname);
          }}
        >
          ФИО
        </TableSortLabel>
      ),
      width: 350,
      render: (value, record) => <Typography>{getUserFio(record)}</Typography>,
    },
    {
      title: 'E-mail',
      width: 350,
      render: (value, record) => <Typography>{record.user.email}</Typography>,
    },
    {
      title: 'Роль',
      width: 200,
      render: (value, record) => userRoleToLocale(record.role),
    },
    {
      title: (
        <TableSortLabel
          order={orderBy === DigitalUserOrderBy.createdAt ? order : undefined}
          onChangeOrder={(v): void => {
            setOrder(v as Order);
            setOrderBy(DigitalUserOrderBy.createdAt);
          }}
        >
          Дата создания
        </TableSortLabel>
      ),
      width: 200,
      render: (value, record) => toHumanDate(record.created_at),
    },
    {
      title: (
        <TableSortLabel
          order={orderBy === DigitalUserOrderBy.updatedAt ? order : undefined}
          onChangeOrder={(v): void => {
            setOrder(v as Order);
            setOrderBy(DigitalUserOrderBy.updatedAt);
          }}
        >
          Дата изменения
        </TableSortLabel>
      ),
      width: 200,
      render: (value, record) => toHumanDate(record.updated_at),
    },
    // eslint-disable-next-line
    tableCellMore<IDigitalUser>((record) => {
      return (e): void => {
        if (menuState.open) {
          menuState.onClose();
        }
        else {
          menuState.show(e);
          setSelectedUser(record);
        }
      };
    }),
  ];

  return (
    <>
      <div className={classes.root}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="h2" fontWeight="bold" style={{ marginBottom: 24 }}>
            Управление пользователями
          </Typography>

          <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Stack direction="row" spacing={16} style={{ flex: 1 }}>
              <TextField
                style={{ maxWidth: 480 }}
                fullWidth
                placeholder="Найти пользователя"
                InputProps={{ endAdornment: <SearchIcon /> }}
                value={search.value}
                onChange={(e): void => {
                  search.change(e.target.value);
                  pagination.onChangePage(0);
                }}
              />
            </Stack>

            <Button variant="outlined" startIcon={<AddIcon />} onClick={(): void => dialogCreate.open()}>
              Добавить пользователя
            </Button>
          </div>

          <Table<IDigitalUser>
            columns={columns}
            loading={loading}
            rowKey="_id"
            data={data}
            hover={false}
            footer={(): JSX.Element => (
              <TablePagination
                style={{ marginTop: 16 }}
                total={total}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                onChangePage={pagination.onChangePage}
                onChangeRowsPerPage={pagination.onChangeRowsPerPage}
              />
            )}
          />
        </Paper>
      </div>

      <Menu {...menuState.props} placement="bottom-right">
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            editDialog.open(selectedUser);
          }}
        >
          Изменить роль
        </MenuItem>
        <MenuItem
          className={classes.menuItemDelete}
          onClick={(): void => {
            menuState.onClose();

            if (selectedUser) {
              confirmDelete.open(selectedUser);
            }
          }}
        >
          Удалить
        </MenuItem>
      </Menu>

      <Confirm
        Icon={DeleteIcon}
        color="error"
        title="Удалить пользователя"
        summary={`Вы уверены, что хотите удалить пользователя ${selectedUser ? getUserFio(selectedUser) : ''}?`}
        {...confirmDelete.props}
        confirmLabel="Удалить пользователя"
        onConfirm={(): void => {
          if (confirmDelete.data) {
            handleDelete(confirmDelete.data);
          }
        }}
      />
      <DialogUserEdit {...editDialog.props} onSubmit={handleEdit} />
      <DialogUserCreate {...dialogCreate.props} onSubmit={handleCreate} />
      <DialogUserCreateSuccess {...dialogSuccess.props} />
    </>
  );
});
