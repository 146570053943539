import { DigitalStore } from '../digital/digital.store';
import { AuthStore } from './auth.store';
import { ConfigStore } from './config.store';

export class RootStore {

  public readonly config: ConfigStore;

  public digital: DigitalStore;
  public readonly auth: AuthStore;

  public get today(): Date {
    return new Date();
  }

  constructor() {
    this.auth = new AuthStore(this);
    this.config = new ConfigStore();
    this.digital = new DigitalStore(this);
  }

}
