import React, { FC } from 'react';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import { Snackbar } from '@unione-pro/unione.digital.commons.ui-kit/lib/snackbar';
import { appTheme } from '@unione-pro/unione.digital.commons.ui-kit/lib/theme';
import '@unione-pro/unione.digital.commons.ui-kit/lib/index.css';
import { appRoutesDefinition } from './routes/app.routes';
import { Access } from './views/components/access';
import { NoServer } from './views/components/app-no-server';
import { Auth } from './views/components/auth';

const Content: React.FC = () => {
  const appRoutes = useRoutes(appRoutesDefinition);

  return appRoutes;
};

export const App: FC = () => (
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={appTheme}>
        <Snackbar />
        <Auth>
          <NoServer>
            <Access>
              <Wrapper>
                <Content />
                <Snackbar />
              </Wrapper>
            </Access>
          </NoServer>
        </Auth>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
