import React, { HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import { LocationIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/location';
import { MailIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/mail';
import { PhoneIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/phone';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';

export type AppFooterProps = HTMLAttributes<HTMLDivElement>;

const PREFIX = 'AppFooter';
const useStyles = createUseStyles(
  () => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: '54px 0px',
      paddingTop: 22,
    },
    content: {
      width: '100%',
      maxWidth: '900px',
    },
    block: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
    },
  }),
  { name: PREFIX },
);

export const AppFooter: React.FC<AppFooterProps> = (props) => {
  const { className, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    <div className={rootCN} {...other}>
      <Stack className={classes.content} justifyContent="space-between">
        <a className={classes.block} href="tel:+7(800)-550-31-71" target="_blank" rel="noreferrer">
          <PhoneIcon color="textSecondary" />

          <Typography color="textSecondary" variant="caption">
            +7 (800) 550 31 71
          </Typography>
        </a>
        <a className={classes.block} href="https://yandex.ru/maps/-/CCU6QUA1-D" target="_blank" rel="noreferrer">
          <LocationIcon color="textSecondary" />

          <Typography color="textSecondary" variant="caption">
            420500, г. Иннополис, ул. Университетская, д.1
          </Typography>
        </a>
        <a className={classes.block} href="mailto:support.unionepro@innopolis.ru" target="_blank" rel="noreferrer">
          <MailIcon color="textSecondary" />

          <Typography color="textSecondary" variant="caption">
            support.unionepro@innopolis.ru
          </Typography>
        </a>
      </Stack>
    </div>
  );
};
