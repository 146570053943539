export class BrowserRoute {

  public static main = '/';

  public static notFound = '/not-found';

  public static users = '/';

  public static user = (id: string = ':id'): string => `${BrowserRoute.users}/${id}`;

  public static organizations = '/organization';

  public static organization = (id: string = ':id'): string => `${BrowserRoute.organizations}/${id}`;

  public static organizationUsers = (id: string = ':id'): string => `${BrowserRoute.organization(id)}/users`;

  public static organizationCourses = (id: string = ':id'): string => `${BrowserRoute.organization(id)}/courses`;

  public static organizationRequests = (id: string = ':id'): string => `${BrowserRoute.organization(id)}/requests`;

}
