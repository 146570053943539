import React from 'react';
import { createUseStyles } from 'react-jss';
import { getUserFio } from '@app/user';
import { DigitalUserRole } from '@unione-pro/unione.digital.commons.sdk';
import { Button, Dialog, DialogFooter, DialogHeader } from '@unione-pro/unione.digital.commons.ui-kit';
import { FormLabel } from '@unione-pro/unione.digital.commons.ui-kit/lib/form-label';
import { UserIcon } from '@unione-pro/unione.digital.commons.ui-kit/lib/icons/user';
import { Select, SelectItem } from '@unione-pro/unione.digital.commons.ui-kit/lib/select';
import { Stack } from '@unione-pro/unione.digital.commons.ui-kit/lib/stack';
import { Typography } from '@unione-pro/unione.digital.commons.ui-kit/lib/typography';
import cn from 'classnames';
import { DialogUserEditProps } from './dialog-user-edit.models';

const PREFIX = 'DialogUserEdit';
const useStyles = createUseStyles(() => ({ root: { maxWidth: '500px' } }), { name: PREFIX });

export const DialogUserEdit: React.FC<DialogUserEditProps> = (exampleProps) => {
  const { className, data, onClose, onSubmit, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const [role, setRole] = React.useState<DigitalUserRole>(DigitalUserRole.user);

  React.useEffect(() => {
    if (data) {
      setRole(data.role);
    }
  }, [data]);

  const handleSubmit = (): void => {
    if (onSubmit && data) {
      onSubmit(data, role);
    }
  };

  return (
    <Dialog color="primary" className={rootCN} onClose={onClose} {...other}>
      <DialogHeader onClose={onClose}>
        <UserIcon color="primary" style={{ width: '28px', height: '28px' }} />
      </DialogHeader>

      <Stack direction="column" spacing={40} style={{ marginTop: '43px' }}>
        <Typography variant="h3" style={{ fontWeight: 600 }}>{data ? getUserFio(data) : ''}</Typography>

        <Stack direction="column" spacing={4}>
          <FormLabel required>Роль</FormLabel>

          <Select
            style={{ width: '100%' }}
            variant="outlined"
            value={role}
            onChange={(v): void => setRole(v as DigitalUserRole)}
          >
            <SelectItem value={DigitalUserRole.operator}>Оператор</SelectItem>
            <SelectItem value={DigitalUserRole.provider}>Провайдер</SelectItem>
            <SelectItem value={DigitalUserRole.user}>Пользователь</SelectItem>
            <SelectItem value={DigitalUserRole.providerAdmin}>Провайдер-Админ</SelectItem>
            <SelectItem value={DigitalUserRole.operatorAdmin}>Оператор-Админ</SelectItem>
          </Select>
        </Stack>
      </Stack>

      <DialogFooter style={{ marginTop: '40px', marginBottom: '-16px' }}>
        <Stack
          direction="column"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <Button
            fullWidth
            variant="contained"
            style={{ justifyContent: 'center', height: '44px', fontSize: '16px' }}
            onClick={handleSubmit}
          >
            Изменить роль
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="gray"
            style={{ justifyContent: 'center', border: 'none', height: '44px', color: '#6F7C98', fontSize: '16px' }}
            onClick={onClose}
          >
            Отмена
          </Button>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
};
